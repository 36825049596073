// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import App from './App';

// OIDC configuration for Cognito
const oidcConfig = {
  authority: 'https://hairdesignbyrenata.auth.us-east-1.amazoncognito.com',  // Cognito domain
  client_id: '3a096ouophg042qcjbmceogn9m',                                    // App Client ID
  redirect_uri: 'https://hairdesignbyrenata.com/',                            // Callback URL after login
  response_type: 'code',
  scope: 'openid email phone',
  onSigninCallback: (_user) => {
    // Remove OAuth query params from URL after sign-in to clean up
    window.history.replaceState({}, document.title, window.location.pathname);
  }
};

// Wrap the app with AuthProvider for OIDC
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>
  </React.StrictMode>
);
