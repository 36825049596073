// src/App.js
import React from 'react';
import { useAuth } from 'react-oidc-context';
import CancelAppointmentForm from './components/CancelAppointmentForm';
import BookAppointmentForm from './components/BookAppointmentForm';
import styles from './App.module.css';

// Import your other components as needed
// e.g., import Header from './Header'; import Booking from './Booking'; import Cancel from './Cancel';

function App() {
  const auth = useAuth();  // get authentication state & methods

  // Define logout function to clear local session and redirect to Cognito logout
  const signOut = async () => {
    await auth.removeUser();  // clear user session locally
    // Redirect to Cognito hosted UI logout endpoint with redirect back to our site
    const clientId = '3a096ouophg042qcjbmceogn9m';
    const logoutRedirect = 'https://hairdesignbyrenata.com/';  // post-logout redirect URI
    const cognitoDomain = 'https://hairdesignbyrenata.auth.us-east-1.amazoncognito.com';
    window.location.href = 
      `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(logoutRedirect)}`;
  };

  // Handle intermediate auth states
  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <div>Signing you in...</div>;
    case 'signoutRedirect':
      return <div>Signing you out...</div>;
    default:
      // continue to render normally
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Authentication Error: {auth.error.message}</div>;
  }

  // Now render the app UI based on authentication state
  return (
    <div>
      {/* Header / Navigation */}
      <header>
        {auth.isAuthenticated ? (
          /** If logged in: show a welcome message and Logout button **/
          <div>
            Welcome, **{auth.user?.profile.email}**!{' '}
            <button onClick={signOut}>Logout</button>
          </div>
        ) : (
          /** If not logged in: show Login button to trigger Hosted UI **/
          <button onClick={() => auth.signinRedirect()}>Login</button>
        )}
      </header>

      {/* Main Content */}
      <main>
        {auth.isAuthenticated ? (
          /** Protected content for authenticated users **/
          <div>
            {/* Appointment Booking and Cancellation components are only shown when logged in */}
            {/* Replace <Booking /> and <Cancel /> with your actual component names */}
            <BookAppointmentForm />  
            <CancelAppointmentForm />

            {/* Display user info and tokens */}
            <h3>User Information</h3>
            <p><strong>Email:</strong> {auth.user?.profile.email}</p>
            <p><strong>ID Token:</strong> {auth.user?.id_token}</p>
            <p><strong>Access Token:</strong> {auth.user?.access_token}</p>
          </div>
        ) : (
          /** Message or alternate content for unauthenticated users **/
          <p>Please log in to book or cancel appointments.</p>
        )}
      </main>
    </div>
  );
}

export default App;
